import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { TableWrapper } from "Styles/global";
import { useDispatch, useSelector } from "react-redux";
import { loading as stateLoading, data as stateData, userProfileData } from "Redux/App";
import { getCompanies } from "Redux/App/Actions/Companies";
import EditCompanyDrawer from "./EditCompanyDrawer";
import { EditCompany } from "Redux/App/Actions/Companies";
import MainModal from "./main/Modal";
import { COMPANY } from "constants/Company";
import tableColumns from "./main/tableColumns";
import NewPromoIcon from "./main/NewPromoIcon";

import FileNameModal from "Components/Shared/FileNameModal/FileNameModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { ExportButton } from "Pages/Setting/styles";
import { getAllTimezones } from "Requests/timezone";

// File Type
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const Companies = () => {
  const dispatch = useDispatch();
  const data = useSelector(stateData);
  const permissionLevel = useSelector(userProfileData)?.permission_level;
  const tableLoading = useSelector(stateLoading);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [newCompany, createNewCompany] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(COMPANY.INITIAL_DRAWER_VALUES);
  const [companyData, setCompanyData] = useState({});
  const [timezones, setTimezones] = useState([]);
  const [fetchCompanies, toggleFetchCompanies] = useState(false);

  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileName, setFileName] = useState(`Companies_${moment().format("YYYYMMDD_HHmmss")}`);

  // Exporting
  const exportToCSV = (csvData, fileName) => {
    const myHeader = [
      "Id",
      "Coupon",
      "Create Promotion",
      "Company Name",
      "Logo URL",
      "Xibo Folder ID",
      "Status",
      "Vistar Name",
      "Advertiser ID"
    ];
    // Extension
    const fileExtension = ".xlsx";

    const finalData = csvData.map((item) => {
      return {
        Id: item.id,
        Coupon: item.coupon,
        "Create Promotion": item.create_promo_flag,
        "Company Name": item.company_name,
        "Logo URL": item.logo_url,
        "Xibo Folder ID": item.xibo_folder_id,
        Status: item.status,
        "Vistar Name": item.vistar_name,
        "Advertiser ID": item.company_ad_id
      };
    });

    const ws = XLSX.utils.json_to_sheet(finalData, { header: myHeader });

    const wb = { Sheets: { Companies: ws }, SheetNames: ["Companies"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const e_data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(e_data, fileName + fileExtension);
    setOpenFileModal(false);
    setFileName(`Companies_${moment().format("YYYYMMDD_HHmmss")}`);
  };

  const clickExport = () => {
    setOpenFileModal(true);
  };

  const handleExport = () => {
    exportToCSV(data, fileName ?? "Companies");
  };

  const handleOk = () => {
    dispatch(
      EditCompany(
        {
          ...selectedCompany,
          coupon: false
        },
        setLoading,
        null,
        null,
        fetchCompanies,
        toggleFetchCompanies
      )
    );
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch, fetchCompanies]);

  useEffect(() => {
    const fetchTimezones = async () => {
      const response = await getAllTimezones();
      setTimezones(response);
    };
    fetchTimezones();
  }, []);

  const columns = tableColumns(
    setSelectedCompany,
    setIsModalOpen,
    dispatch,
    EditCompany,
    setLoading,
    drawerOpen,
    setDrawerOpen,
    setCompanyData,
    createNewCompany,
    companyData,
    fetchCompanies,
    toggleFetchCompanies
  );

  return (
    <TableWrapper>
      <FileNameModal
        fileName={fileName}
        setFileName={setFileName}
        open={openFileModal}
        handleOk={handleExport}
        handleCancel={() => setOpenFileModal(false)}
      />
      <NewPromoIcon
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        createNewCompany={createNewCompany}
        permissionLevel={permissionLevel}
      />
      <div style={{ textAlign: "end", marginBottom: "10px" }}>
        <ExportButton onClick={clickExport}>Export Table</ExportButton>
      </div>
      <Table
        loading={tableLoading || !Array.isArray(data)}
        dataSource={Array.isArray(data) ? data : []}
        columns={columns}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: COMPANY.PAGE_NO_ITEMS,
          defaultPageSize: 10
        }}
      />
      <EditCompanyDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        companyData={companyData}
        newCompany={newCompany}
        timezones={timezones}
        fetchCompanies={fetchCompanies}
        toggleFetchCompanies={toggleFetchCompanies}
      />
      <MainModal isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} />
    </TableWrapper>
  );
};

export default Companies;
