import React, { useState } from "react";
import { Select } from "antd";
import { Option } from "antd/es/mentions";

export const Domo = () => {
  const dashboards = [
    {
      label: "Example Marketing Dashboard",
      value: "https://embed.domo.com/embed/pages/nxYR7"
    },
    {
      label: "Example Sales Dashboard",
      value: "https://embed.domo.com/embed/pages/jZvYP"
    },
    {
      label: "Pct of Time",
      value: "https://embed.domo.com/cards/NL73v"
    },
    {
      label: "By Type",
      value: "https://embed.domo.com/cards/OMJ3r"
    }
  ];

  const [selectedDashboard, setSelectedDashboard] = useState(dashboards[0].value);

  return (
    <>
      <Select value={selectedDashboard} onChange={(e) => setSelectedDashboard(e)}>
        {dashboards.map((entry) => {
          return <Option value={entry.value}>{entry.label}</Option>;
        })}
      </Select>
      <embed
        src={selectedDashboard}
        width="100%"
        height="95%"
        style={{
          marginTop: "12px",
          padding: 0
        }}
      />
    </>
  );
};
