import { Drawer, Form } from "antd";
import React, { useState, useEffect } from "react";
import { Input, Select } from "antd";

import { DrawerTitle, MainTitle, CloseBtnWrapper } from "Styles/global";

import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { useDispatch } from "react-redux";
import { EditCompany, addNewCompany, getVistarEnv } from "Redux/App/Actions/Companies";
import { EDIT_COMPANY } from "constants/Company";
import getRequiredMsg from "utils/formRequiredMsg";
import Submit from "./Edit/Submit";
import EditModal from "./Edit/Modal";
import Loader from "./Edit/Loader";
import { isHexCode } from "utils/isHexCode";

const EditCompanyDrawer = (props) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [vistarList, setVistarList] = useState([]);
  const dispatch = useDispatch();
  const { newCompany } = props;

  const onFinish = (values) => {
    const colorPalette = values?.color_palette ? values.color_palette.split(",").map((it) => it.trim()) : null;
    const mutateValues = {
      ...values,
      color_palette: colorPalette
    };

    setValues(mutateValues);
    const vistarEnvValue = vistarList.find((each) => each.id === values.vistar_env_id)?.vistar_env || null;
    if (newCompany) {
      dispatch(
        addNewCompany(
          {
            ...mutateValues,
            logo_url: values?.logo_url ?? "default"
          },
          setLoading,
          props,
          vistarEnvValue
        )
      );
    } else if (values.company_name !== props.companyData.data.company_name) {
      setIsModalOpen(true);
    } else {
      dispatch(EditCompany(mutateValues, setLoading, form, props, props.fetchCompanies, props.toggleFetchCompanies));
    }

    form.resetFields();
  };

  useEffect(() => {
    if (props?.companyData) {
      const data = { ...props.companyData.data };
      const colorPalette = data.color_palette
        ? JSON.parse(data.color_palette).reduce((a, b) => {
            return `${a}${a ? ", " : ""}${b}`;
          }, "")
        : null;
      const formData = { ...data, color_palette: colorPalette };
      form.setFieldsValue(formData);
    }
  }, [props.companyData]);

  useEffect(() => {
    dispatch(getVistarEnv(setVistarList));
  }, []);

  const handleOk = () => {
    dispatch(EditCompany(values, setLoading, form, props, props.fetchCompanies, props.toggleFetchCompanies));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      title="Edit Company"
      placement={"right"}
      closable={false}
      onClose={() => {
        props.setDrawerOpen(false);
        form.resetFields();
      }}
      open={props.drawerOpen.open}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={() => props.setDrawerOpen(false)}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">{props.drawerOpen.screen} Company</p>
        </MainTitle>
      </DrawerTitle>
      <Loader loading={loading} STYLE={EDIT_COMPANY.DRAWER_TITLE_STYLE} />
      <Form onFinish={onFinish} form={form} layout="vertical">
        {!newCompany && (
          <Form.Item name="id" label="ID">
            <Input disabled />
          </Form.Item>
        )}
        <Form.Item name="company_name" label="Company Name" rules={getRequiredMsg("Please enter company name")}>
          <Input />
        </Form.Item>
        <Form.Item name="coupon" label="Coupon" rules={getRequiredMsg("Please select status")}>
          <Select options={EDIT_COMPANY.COUPON_ARR} />
        </Form.Item>

        <Form.Item name="xibo_folder_id" label="Xibo Folder ID" rules={getRequiredMsg("Please enter Xibo Folder ID")}>
          <Input />
        </Form.Item>

        <Form.Item name="status" label="Status" rules={getRequiredMsg("Please select status")}>
          <Select options={EDIT_COMPANY.STATUS_ARR} />
        </Form.Item>

        <Form.Item
          name="create_promo_flag"
          label="Create Promotion Flag"
          rules={getRequiredMsg("Please select status")}>
          <Select options={EDIT_COMPANY.CREATE_PROMO_ARR} />
        </Form.Item>

        <Form.Item name="vistar_env_id" label="Vistar Name">
          <Select allowClear>
            {vistarList.map((vistar) => (
              <Select.Option value={vistar?.id}>{vistar.vistar_name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="company_ad_id" label="Advertiser ID">
          <Input />
        </Form.Item>
        <Form.Item name="ad_server_tz" label="Timezone">
          <Select allowClear>
            {props.timezones.map((timezone) => (
              <Select.Option value={timezone?.id}>{timezone?.time_zone}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="color_palette"
          label="Color Palette"
          rules={[
            {
              validator(_, value) {
                if (!value) return Promise.resolve();
                const errors = [];
                const hex = value.split(",");

                if (hex.length > 8) errors.push("Cannot contain more than 8 colors");

                for (const color of hex) {
                  if (!isHexCode(color)) errors.push(`${color} is an invalid hex color`);
                }

                if (errors.length > 0) return Promise.reject(errors);
                return Promise.resolve();
              }
            }
          ]}>
          <Input />
        </Form.Item>
        <Submit handleClick={() => props.setDrawerOpen(false)} />
      </Form>
      <EditModal isOpen={isModalOpen && !newCompany} handleOk={handleOk} handleCancel={handleCancel} />
    </Drawer>
  );
};

export default EditCompanyDrawer;
