import { COMPANY } from "constants/Company";
import CouponTable from "./CouponTableRender";
import CreatePromo from "./CreatePromoTableRender";

const TableColumns = (
  setSelectedCompany,
  setIsModalOpen,
  dispatch,
  EditCompany,
  setLoading,
  drawerOpen,
  setDrawerOpen,
  setCompanyData,
  createNewCompany,
  companyData,
  fetchCompanies,
  toggleFetchCompanies
) => {
  return [
    COMPANY.COMPANY_TABLE_ONLY_TITLE,
    {
      ...COMPANY.COUPON_VALUE_TABLE,
      render: (item, record) => (
        <CouponTable
          record={record}
          item={item}
          set={setSelectedCompany}
          dispatch={dispatch}
          edit={EditCompany}
          setLoading={setLoading}
          setModal={setIsModalOpen}
          fetchCompanies={fetchCompanies}
          toggleFetchCompanies={toggleFetchCompanies}
        />
      )
    },
    {
      ...COMPANY.CREATE_PROMO_TABLE,
      render: (item, record) => (
        <CreatePromo
          record={record}
          item={item}
          setSelectedCompany={setSelectedCompany}
          dispatch={dispatch}
          EditCompany={EditCompany}
          setLoading={setLoading}
          fetchCompanies={fetchCompanies}
          toggleFetchCompanies={toggleFetchCompanies}
        />
      )
    },
    {
      ...COMPANY.COMPANY_NAME_TABLE,
      render: (a, item) => (
        <span
          className="link"
          style={COMPANY.COMPANY_NAME_STYLE}
          onClick={() => {
            setDrawerOpen({ ...drawerOpen, open: true, screen: "Edit" });
            setCompanyData({ ...companyData, data: item });
            createNewCompany(false);
          }}>
          {item?.company_name}
        </span>
      )
    },
    ...COMPANY.COMPANY_TABLE_TITLE,
    {
      title: "Color Palette",
      render: (_, record) => {
        if (!record.color_palette) return;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "4px"
            }}>
            {JSON.parse(record.color_palette).map((color, index) => (
              <div key={index} style={{ background: color, height: "12px", width: "12px" }} />
            ))}
          </div>
        );
      }
    }
  ];
};

export default TableColumns;
